<template>
<!-- v-loading="loading" -->
    <div class="bg-white box-shadow border-r px-20 pb-20" v-loading="loading">
        <div class="d-flex-center px-15 border-bottom">
            <h4 class="mr-auto color-333">{{ $t('account.transactions') }}</h4>
            <el-button type="primary" size="mini" icon="el-icon-refresh" plain @click="fatchRecord()">{{ $t('base.refresh') }}</el-button>
        </div>
        <el-row :gutter="20" type="flex" justify="center" align="middle" class="market-header px-20 py-15 border-bottom font-14">
            <el-col :span='3'>{{ $t('transactions.span1') }}</el-col>
            <el-col :span='4' class="breakall">{{ $t('transactions.span2') }}</el-col>
            <el-col :span='4' class="breakall">{{ $t('transactions.span3') }}</el-col>
            <el-col :span='4' class="breakall">{{ $t('transactions.span4') }}</el-col>
            <el-col :span='4' class="breakall">{{ $t('transactions.span5') }}</el-col>

            <el-col :span='4' class="breakall">{{ $t('contact_about.table2.service_charge') }}</el-col>

            <el-col :span='4' class="breakall">{{ $t('transactions.span6') }}</el-col>
            <el-col :span='4' class="breakall">{{ $t('transactions.span7') }}</el-col>
            <el-col class="text-right" :span='4'>{{ $t('transactions.span8') }}</el-col>
        </el-row>
        <el-row v-for="(item, index) in list" :key="index" :gutter="20" type="flex" justify="center" align="middle" class="market-header px-20 py-15 border-bottom font-14">
            <!-- <div>{{item.amount}}</div> -->
            <el-col :span='3'>
                <el-tag v-if="item.type" type="info" effect="plain" hit class="bg-t">Sell</el-tag>
                <el-tag v-else type="primary" effect="plain" hit class="bg-t">Buy</el-tag>
            </el-col>
            <el-col :span='4' class="breakall">{{ item.pair.toUpperCase() }} / {{ item.coin.toUpperCase() }}</el-col>
            <el-col :span='4' class="breakall">{{ item.entrust_price }}</el-col>
            <el-col :span='4' class="breakall">{{ item.amount }}</el-col>
            <el-col :span='4' class="breakall">{{item.price}}</el-col>

            <el-col :span='4' class="breakall">{{ item.service_charge  }}</el-col>

            <el-col :span='4' class="breakall">{{item.total}}</el-col>
            <el-col :span='4' class="breakall">{{ item.done_time }}</el-col>
            <el-col class="text-right" :span='4'>
                <!-- <el-tag :type="state[item.state][0]" effect="plain" hit class="bg-t">{{ state[item.state][1] }}</el-tag> -->
                <!-- <el-tag v-else type="primary" effect="plain" hit class="bg-t">{{ state_new[Number(item.state)] }}</el-tag> -->
                <el-tag v-if="item.state!=0" :type="item.state==1||item.state==3?'danger':'success'" effect="plain" hit class="bg-t" :disable-transitions="true">{{ state_new[Number(item.state)] }}</el-tag>
                <el-button v-if="item.state==0" type="danger" class="newbtn" size="mini" @click="cancelFn(item.id)">{{ $t('transactions.cancelBtn') }}</el-button>
            </el-col>
        </el-row>
        <div class="pagination-outline">
            <div
                v-if="list.length < 1"
                class="py-50 font-14 color-i text-center"
            >
                No More
            </div>
            <div class="pagination-box" v-else>
                <el-pagination
                    layout="prev, pager, next"
                    background
                    :total="total1"
                    :page-size="pageSize"
                    :current-page="page"
                    @current-change="pageFn1"
                >
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            loading: false,
            record: {
                data: []
            },
            state: [
                ['primary', 'Trading'],
                ['info', 'Cancel'],
                ['success', 'Deal']
            ],
            state_new:[this.$t('transactions.state_new1'),this.$t('transactions.state_new2'),this.$t('transactions.state_new3'),this.$t('transactions.state_new4')],
            list:[],
            page:1,
            pageSize:10,
            total1:0,
        }
    },
    created: function() {
        // this.fatchRecord()
    },
    mounted: function(){
        setTimeout(()=>{
            this.fatchRecord()
        },200)
    },
    methods: {
        fatchRecord: async function () {
            this.loading = true
            // const { data } = await this.$request.get('transaction/order_pc/coin/'+this.$route.params.coin)
             const { data } = await this.$request.post('v1/symbol/getOrderList',{
                page:this.page,
                pageSize:this.pageSize
             })
            this.list = data.list
            this.total1 = data.total;
            this.loading = false

            // this.loading = true
            // // const { data } = await this.$request.get('transaction/order_pc/coin/'+this.$route.params.coin)
            //  const { data } = await this.$request.get('transaction/order_pc/coin/'+'usdt')
            // this.list = data.data
            // console.log(this.list)
            // this.loading = false
        },

        cancelFn: async function(id){
            try {
                this.loading = true;
                let $data = await this.$request.post("v1/symbol/revocation", {
                id: id,
                });
                // this.tradingLoading = false;
                // this.tradingList.splice(index, 1);
                let that = this;
                this.$message({
                showClose: true,
                type: "success",
                duration: 2000,
                message: $data.message,
                onClose: function () {
                    that.total1=0
                    that.page=1
                    that.fatchRecord();
                },
                });
            } catch (error) {
                this.loading = false;
            }
        },
        pageFn1: function(e){
            this.page=e
            this.fatchRecord();
        },
    }
}
</script>
<style lang="scss" scoped>
//
.pagination-outline{
    padding-top: 20px;
    box-sizing: border-box;
    text-align: center;
}
.newbtn{
    height: 32px;
    padding: 0 10px;
}
.breakall{
    word-break: break-all;
}
</style>